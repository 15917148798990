<template>
  <div class="container">
    <div
      class="row mt-0 pt-0 mt-md-5 mt-lg-0 pt-lg-5 mb-5 pb-0 mb-md-5 pb-md-0"
    >
      <div class="col-12 col-lg-6 col-xl-5 offset-xl-1 text-center">
        <div class="d-grid m-auto">
          <router-link
            @click="hidden = !hidden"
            class="btn btn-rainbow pt-4 pb-4"
            to="/szolgaltatasaink"
          >
            <span>Ismerje meg szolgáltatásainkat!</span>
          </router-link>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-5 text-center mt-3 mt-lg-0">
        <div class="d-grid m-auto">
          <router-link
            @click="hidden = !hidden"
            class="btn btn-rainbow pt-4 pb-4"
            to="/termekeink"
          >
            <span>Ismerje meg termékeinket!</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <SectionTitle
    v-once
    icon="icon-about"
    h2="(Tényleg) Röviden az <br
      class='d-none d-lg-block'> ARDA KFT.-ről"
    h2small="„Sokáig munkálkodtak EA tágas tereien, amelyek tágasabbak, mintsem a tündék és emberek gondolnák, mígnem <br class='d-none d-lg-block'> a meghatározott időben elkészült ARDA, a Föld királysága.” <br> <small>— J. R. R. Tolkien - A SZILMARILOK (Gálvölgyi Judit fordítása) —</small>"
    createdate=""
  />

  <section v-once class="section-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5 text-center">
          <h3 class="h3-date h3-date-1 pt-5 mt-0 mb-0 mx-auto">// 2002.</h3>
          <br />
          <p class="lead">
            Az ARDA Szolgáltató Kft. 2002-től készít weboldalakat,
            webáruházakat. Az összegyűlt tapasztalat és a folyamatos fejlődés
            stabil szakmai minőséget garantál minden jelenlegi és jövőbeli
            partnerünknek! Az internet hajnalán saját grafikai arculattal, saját
            fejlesztésű keretrendszerrel, adminisztrációs felületekkel nagy
            látogatottságú weboldalakat üzemeltettünk illetve készítettünk
            micro- és kisvállalatoknak weboldalakat, arculatokat, reklámokat.
          </p>

          <div class="d-none d-lg-block">
            <h3 class="h3-date h3-date-2 pt-5 mt-5 mb-4 mx-auto">// 2013.</h3>
            <p class="lead">
              2013-től a Magento webáruházak készítése mellett több
              alvállalkozói kapcsolatot építettünk ki és elindítottuk sitebuild
              szolgáltatásunkat valamint szintén alvállalkozóként
              bannerkészítéseket is vállalunk. Megtartottuk a micro- és
              kisvállalatoknak szánt weboldal készítési szolgáltatásunkat is.
              Készítünk dobozos termékeket is elsősorban Magento webáruházakhoz.
              A legsikeresebb termékünk a folyamatosan frissülő és karbantartott
              magyar nyelvi csomag Magento Open Source 1.x és Magento Open
              Source 2.x webáruházakhoz.
            </p>
          </div>
        </div>
        <div class="d-none d-lg-block col-lg-2 mt-5 pt-5">
          <img
            src="@/assets/img/all/bg-about-line.svg"
            alt="Mit csinálunk?"
            class="img-fluid"
          />
        </div>
        <div class="col-12 col-lg-5 text-center">
          <h3 class="h3-date h3-date-3 pt-5 mt-5 mb-4 mx-auto">// 2008.</h3>
          <p class="lead">
            A Magento e-commerce megjelenésével cégünk stratégiájában gyökeres
            változás történt. Időnk jelentős részét a Magento webáruház motor
            megismerésére fordítottuk, felhagytunk az egyedi fejlesztésű oldalak
            építésével és elköteleztük magunkat a Magento mellett. Döntésünk
            helyesnek bizonyult, hiszen egy olyan webáruház e-kereskedelmi
            platformmal dolgozhatunk, amely megfelel a jelenkori elvárásoknak.
          </p>

          <div class="d-lg-block d-lg-none">
            <h3 class="h3-date h3-date-2 pt-5 mt-5 mb-4 mx-auto">// 2013.</h3>
            <p class="lead">
              2013-től a Magento webáruházak készítése mellett több
              alvállalkozói kapcsolatot építettünk ki és elindítottuk sitebuild
              szolgáltatásunkat valamint szintén alvállalkozóként bannerek
              készítését is vállaltunk. Megtartottuk a micro- és
              kisvállalatoknak szánt weboldal készítési szolgáltatásunkat is.
              Készítünk dobozos termékeket is elsősorban Magento webáruházakhoz.
              A legsikeresebb termékünk a folyamatosan frissülő és karbantartott
              magyar nyelvi csomag Magento Open Source 1.x és Magento Open
              Source 2.x webáruházakhoz.
            </p>
          </div>

          <h3 class="h3-date h3-date-4 pt-5 mt-5 mb-4 mx-auto">// 2021.</h3>
          <p class="lead">
            Megújult arculattal, letisztult szolgáltatási- és termék palattával
            segítünk a cégeknek a projektek minden szakaszában, kezdve az
            igények felmérésétől a végleges átadásig, sőt az átadás utáni
            nyomkövetésben, frissítésekben is. Hiszünk a gyors és pontos
            kommunikációban, a korrekt árképzésben, a becsületes munkában. Mivel
            lassan 20 éve jelen vagyunk a piacon biztosíthatjuk, hogy stabil
            alapokon működünk, nem célunk, hogy partnereinknek olyat igérjünk,
            amit nem tudunk teljesíteni.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {};
  },
};
</script>

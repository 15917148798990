<template>
  <SectionTitle
    v-once
    icon="icon-about"
    h2="Küldetésünk, <br
      class='d-none d-lg-block'> céges filozófiánk"
    h2small="Létezik az igérgetésektől, határidő csúszásoktól, horribilis fejlesztési összegektől mentes közös munka?
<br
      class='d-none d-lg-block'>A válaszunk röviden: igen"
    createdate=""
  />

  <section v-once class="section-body">
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-lg-5 text-center hidden-left col-show col-show-left"
        >
          <img
            src="@/assets/img/all/icon-about-kik.svg"
            alt="Kik az ügyfeleink?"
            class="img-fluid"
          />
          <br />
          <h3 class="mt-2 mt-lg-4">// Kik az ügyfeleink?</h3>
          <p class="lead">
            Olyan vállalkozásokkal, cégekkel, dolgozunk együtt akik termékeiket
            vagy szolgáltatásaikat kiemelt figyelemmel kezelik és fontosnak
            tartják az egymást segítő partneri együttműködést. Ügyfeleink között
            található magyar és külföldi egyéni vállalkozó, micro-, kis- és
            középvállalat is. Vannak olyan partnereink, akikkel már már több,
            mint 20 éve dolgozunk együtt.
          </p>
          <div class="mt-0 mt-lg-5">
            <img
              src="@/assets/img/all/icon-about-hogyan.svg"
              alt="Hogyan készítjük?"
              class="img-fluid"
            />
            <br />
            <h3 class="mt-2 mt-lg-4">// Hogyan készítjük?</h3>
            <p class="lead">
              Az ügyfelek elképzeléseket meghallgatjuk, ésszerű javaslatokat,
              ötleteket teszünk. Próbálunk mindig több megoldást kínálni az
              adott feladatra. Hiszünk a nyílt, őszinte kommunikációban és a
              kölcsönös tiszteletben. Partneri viszonyban a közös siker
              elérésére törekszünk.
            </p>
          </div>
        </div>
        <div class="d-none d-lg-block col-lg-2 mt-0 pt-0 mt-lg-5 pt-lg-5">
          <img
            src="@/assets/img/all/bg-about-line.svg"
            alt="Mit csinálunk?"
            class="img-fluid"
          />
        </div>

        <div
          class="col-12 col-lg-5 mt-5 text-center hidden-right col-show col-show-right"
        >
          <img
            src="@/assets/img/all/icon-about-mit.svg"
            alt="Mit csinálunk?"
            class="img-fluid"
          />
          <br />
          <h3 class="mt-2 mt-lg-4">// Mit csinálunk?</h3>
          <p class="lead">
            20 éves webes tapasztalattal landing oldalakat, sitebuild munkákat,
            weboldalakat készítünk és tartunk karban. Grafikai tervezést és
            kivitelezést is végzünk, legyen az egy egyszerű banner elkészítése
            vagy egy komplex design guide megalkotása.
          </p>

          <div class="mt-lg-5 pt-lg-5">
            <img
              src="@/assets/img/all/icon-about-mennyiert.svg"
              alt="Mennyiért dolgozunk?"
              class="img-fluid"
            />
            <br />
            <h3 class="mt-2 mt-lg-4">// Mennyiért dolgozunk?</h3>
            <p class="lead">
              Magyarországi és nemzetközi viszonylatban is korrekt ár-érték
              aránnyal dolgozunk. Partnereinkkel hosszú távú, sikeres
              együttműködésre törekszünk. Nem célunk az egyszeri alkalomra
              szóló, összecsapott munka.
            </p>
          </div>
        </div>
      </div>
      <SectionServicesProducts />
    </div>
  </section>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import SectionServicesProducts from "@/components/SectionServicesProducts.vue";

export default {
  components: {
    SectionTitle,
    SectionServicesProducts,
  },
  data() {
    return {};
  },
};
</script>
